<script>
/* eslint-disable */
</script>
<template>
  <div class="home">
    <section :class="'home__head ' + $root.site+'s'">
      <div class="bg-video bg-video_visible">
        <!--video poster="../assets/img/new/main.jpg" preload autoplay muted loop class="bg-video__media">
          <--source type="video/mp4" src="https://mriyaresort.com/video/fine_3.mp4">
          <source type="video/ogg; codecs='theora, vorbis'" src="https://mriyaresort.com/video/fine_3.ogv">
          <source type="video/webm" src="https://mriyaresort.com/video/fine_3.webm"->
        </video-->
      </div>
      <div class="home__head-logo">
        <div v-if="$root.site === 'abaata'">
          <img class="newYear-central-logo" src="../assets/img/header/header__logo.svg" alt="">
<!--          <div class="newYear-central-cap">
            <img src="../assets/img/Home/cap_logo.svg" alt="">
          </div>-->
        </div>
        <div v-else>
          <img class="newYear-central-logo" src="../assets/general/abaata.svg" alt="">
<!--          <div class="newYear-central-cap-abaata">
            <img src="../assets/img/Home/cap_logo.svg" alt="">
          </div>-->
        </div>
<!--        <img src="../assets/general/abaata.svg" alt="" v-else>-->
        <div class="home__head-logo_title h1">Добро пожаловать!</div>
      </div>
      <slot  v-if="$root.site === 'abaata'">
      <Calendar v-if="widthWindow >= 500" class="calendar-wrap"/></slot>
      <div class="home__head-footer">
        <!--div v-if="widthWindow >= 500" class="left">
          <div class="left__container">
            <img src="../assets/img/Home/maps-kyrort.svg" alt="">
            <p>Карта курорта</p>
          </div>
          <div class="left__container">
            <img src="../assets/img/Home/faq.svg" alt="">
            <p>Вопросы и ответы</p>
          </div>
        </div-->
        <div v-if="widthWindow >= 500" class="middle">
          <a href="#sections" class="scroll-link">
            <span class="scroll-link__icon"></span>
            <span class="scroll-link__text">Крутите вниз</span>
          </a>
        </div>
        <!--div class="right">
          <div class="right__container">
            <img src="../assets/img/Home/info.svg" alt="">
            <p>Важная информация</p>
          </div>
          <div class="right__container">
            <img src="../assets/img/Home/programm.svg" alt="">
            <p>Программа “Забота”</p>
          </div>
        </div-->
      </div>
    </section>
    <section class="carousel" v-if="Object.keys(categories).length && restaurants.length && services.length && $root.site === 'abaata'">
      <Calendar v-if="widthWindow < 500" class="calendar-wrap"/>
      <div v-if="widthWindow > 500" class="carousel__head-wrap">
        <div :class="{'slide-active': sliderActiveCategory === 0}" @click="goTo(0)" class="carousel__head-container pointer slick-active">Размещение</div>
        <div :class="{'slide-active': sliderActiveCategory === 1}" @click="goTo(1)" class="carousel__head-container pointer">Рестораны</div>
        <!--div :class="{'slide-active': sliderActiveCategory === 2}" @click="goTo(2)" class="carousel__head-container pointer">Институт активного долголетия</div>
        <div :class="{'slide-active': sliderActiveCategory === 3}" @click="goTo(3)" class="carousel__head-container pointer">Медицинский центр</div>
        <div :class="{'slide-active': sliderActiveCategory === 4}" @click="goTo(4)" class="carousel__head-container pointer">Инфраструктура</div-->
        <div :class="{'slide-active': sliderActiveCategory === 2}" @click="goTo(2)" class="carousel__head-container pointer">Развлечения</div>
      </div>
      <div v-if="widthWindow > 500" class="carousel__arrow-nav">
        <div :class="{'__disable': sliderActiveCategory === 0}" @click="showPrev('carousel1')" class="arrow-nav-prev pointer">
          <img src="../assets/img/Home/arrowPrev.svg" alt="">
        </div>
        <div :class="{'__disable': sliderActiveCategory === 2}" @click="showNext('carousel1')" class="arrow-nav-next pointer">
          <img src="../assets/img/Home/arrowNext.svg" alt="">
        </div>
      </div>
      <Carousel v-if="widthWindow > 500 && $root.site === 'abaata'" class="carousel-container"
                v-bind="settings"
                ref="carousel1"
      >
        <div :class="{'slide-active': sliderActiveCategory === 0}" class="carousel-slide" v-if="Object.keys(categories).length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Размещение</div>
            <div class="slide-content">
              <router-link tag="div" :to="'/'+$root.site+'/offers/'+category.id" class="slide__image-wrap" v-for="category in categories" :key="category.id">
                <div class="overflow-container" v-if="category.cf">
                  <img :src="category.cf[642]" alt="">
                </div>
                <div class="slide__image-subtitle">{{ category.name }}</div>
              </router-link>
            </div>
          </div>
        </div>
        <div :class="{'slide-active': sliderActiveCategory === 1}" class="carousel-slide" v-if="restaurants.length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Рестораны</div>
            <div class="slide-content">
              <router-link tag="div" :to="'/'+$root.site+'/restaurant/'+restaurant.id" class="slide__image-wrap" v-for="restaurant in restaurants" :key="restaurant.id">
                <div class="overflow-container" v-if="restaurant.cf">
                  <img :src="restaurant.cf[645]" alt="">
                </div>
                <div class="slide__image-subtitle">{{ restaurant.name }}</div>
              </router-link>
            </div>
          </div>
        </div>
        <!--div :class="{'slide-active': sliderActiveCategory === 2}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Институт активного долголетия</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'slide-active': sliderActiveCategory === 3}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Медицинский центр</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'slide-active': sliderActiveCategory === 4}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Инфраструктура</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div-->
        <div :class="{'slide-active': sliderActiveCategory === 2}" class="carousel-slide" v-if="services.length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Развлечения</div>
            <div class="slide-content">
              <slot v-for="service in services">
              <router-link tag="div" :to="'/'+$root.site+'/service/'+service.id" class="slide__image-wrap" :key="service.id" v-if="service.cf && service.cf[643] && service.cf[643] !== undefined">
                <div class="overflow-container" v-if="service.cf">
                  <img :src="service.cf[643]" alt="">
                </div>
                <div class="slide__image-subtitle">{{ service.name }}</div>
              </router-link>
              </slot>
            </div>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            <input hidden type="text" :value="sliderActiveCategory = arrowOption.currentSlide">
          </div>
        </template>
        <template style="display: none" #nextArrow="arrowOption">
          <div class="custom-arrow" style="display: none">
            {{arrowOption.currentSlide}}
          </div>
        </template>
      </Carousel>
      <div v-if="widthWindow < 500 && $root.site === 'abaata'" class="carousel-mob__wrap">
        <router-link tag="div" :to="'/'+$root.site+'/offers'" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Размещение</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1642500331_2 Размещение обложка каталога.jpg.jpg" alt="">
          </div>
        </router-link>
        <router-link tag="div" :to="'/'+$root.site+'/restaurants'" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Рестораны</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1642500333_3 Рестораны обложка каталога.jpg.jpg" alt="">
          </div>
        </router-link>
        <router-link tag="div" :to="'/'+$root.site+'/services'" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Развлечения</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1642500335_4 Развлечения обложка каталога.jpg.jpg" alt="">
          </div>
        </router-link>
      </div>
    </section>



    <section class="carousel" v-if="Object.keys(categories).length && $root.site === 'picunda'">
<!--      <Calendar v-if="widthWindow < 500" class="calendar-wrap"/>-->
      <div v-if="widthWindow > 500" class="carousel__head-wrap">
        <div :class="{'slide-active': sliderActiveCategory === 0}" @click="goTo(0)" class="carousel__head-container pointer slick-active">Размещение</div>
<!--        <div :class="{'slide-active': sliderActiveCategory === 1}" @click="goTo(1)" class="carousel__head-container pointer">Рестораны</div>-->
        <!--div :class="{'slide-active': sliderActiveCategory === 2}" @click="goTo(2)" class="carousel__head-container pointer">Институт активного долголетия</div>
        <div :class="{'slide-active': sliderActiveCategory === 3}" @click="goTo(3)" class="carousel__head-container pointer">Медицинский центр</div>
        <div :class="{'slide-active': sliderActiveCategory === 4}" @click="goTo(4)" class="carousel__head-container pointer">Инфраструктура</div-->
<!--        <div :class="{'slide-active': sliderActiveCategory === 2}" @click="goTo(2)" class="carousel__head-container pointer">Развлечения</div>-->
      </div>
      <div v-if="widthWindow > 500" class="carousel__arrow-nav">
<!--        <div :class="{'__disable': sliderActiveCategory === 0}" @click="showPrev('carousel1')" class="arrow-nav-prev pointer">
          <img src="../assets/img/Home/arrowPrev.svg" alt="">
        </div>
        <div :class="{'__disable': sliderActiveCategory === 0}" @click="showNext('carousel1')" class="arrow-nav-next pointer">
          <img src="../assets/img/Home/arrowNext.svg" alt="">
        </div>-->
      </div>
      <div v-if="widthWindow > 500" class="carousel-container"
                v-bind="settings"
                ref="carousel1"
      >
        <div :class="{'slide-active': sliderActiveCategory === 0}" class="carousel-slide" v-if="Object.keys(categories).length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer" style="text-align: center">Размещение</div>
            <div class="slide-content" style="margin: auto;">
              <slot  v-for="category in categories">
              <router-link tag="div" :to="'/'+$root.site+'/offer/'+number.id" class="slide__image-wrap" v-for="number in category.numbers" :key="number.id">
                <div class="overflow-container" v-if="number.cf">
                  <img :src="number.cf[659]" alt="">
                </div>
                <div class="slide__image-subtitle">{{ number.name }}</div>
              </router-link>
              </slot>
            </div>
          </div>
        </div>
<!--        <div :class="{'slide-active': sliderActiveCategory === 1}" class="carousel-slide" v-if="restaurants.length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Рестораны</div>
            <div class="slide-content">
              <router-link tag="div" :to="'/restaurant/'+restaurant.id" class="slide__image-wrap" v-for="restaurant in restaurants" :key="restaurant.id">
                <div class="overflow-container" v-if="restaurant.cf">
                  <img :src="restaurant.cf[645]" alt="">
                </div>
                <div class="slide__image-subtitle">{{ restaurant.name }}</div>
              </router-link>
            </div>
          </div>
        </div>-->
        <!--div :class="{'slide-active': sliderActiveCategory === 2}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Институт активного долголетия</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'slide-active': sliderActiveCategory === 3}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Медицинский центр</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'slide-active': sliderActiveCategory === 4}" class="carousel-slide">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Инфраструктура</div>
            <div class="slide-content">
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img1.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img2.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Президентские виллы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img3.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Императорская вилла</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img4.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Делюкс</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img5.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Семейные люксы</div>
              </div>
              <div class="slide__image-wrap">
                <div class="overflow-container">
                  <img src="../assets/img/Home/img6.jpg" alt="">
                </div>
                <div class="slide__image-subtitle">Королевские люксы</div>
              </div>
            </div>
          </div>
        </div-->
<!--        <div :class="{'slide-active': sliderActiveCategory === 2}" class="carousel-slide" v-if="services.length">
          <div class="slide-wrap">
            <div class="slide__title h1 pointer">Развлечения</div>
            <div class="slide-content">
              <slot v-for="service in services">
                <router-link tag="div" :to="'/service/'+service.id" class="slide__image-wrap" :key="service.id" v-if="service.cf && service.cf[643] && service.cf[643] !== undefined">
                  <div class="overflow-container" v-if="service.cf">
                    <img :src="service.cf[643]" alt="">
                  </div>
                  <div class="slide__image-subtitle">{{ service.name }}</div>
                </router-link>
              </slot>
            </div>
          </div>
        </div>-->
      </div>
      <div v-if="widthWindow < 500" class="carousel-mob__wrap">
        <router-link tag="div" :to="'/'+$root.site+'/offers'" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Размещение</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1669318533_3. Обложка - размещение.jpg.jpg" alt="">
          </div>
        </router-link>
<!--        <router-link tag="div" to="/restaurants" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Рестораны</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1642500333_3 Рестораны обложка каталога.jpg.jpg" alt="">
          </div>
        </router-link>
        <router-link tag="div" to="/services" class="carousel-mob__container">
          <div class="carousel-mob__container-tit">Развлечения</div>
          <div class="carousel-mob__container-img">
            <img src="https://img1.abaata.ru/uploads/1642500335_4 Развлечения обложка каталога.jpg.jpg" alt="">
          </div>
        </router-link>-->
      </div>
    </section>


    <section class="special" style="display: none">
      <div class="container">
        <div class="special__wrap">
          <div v-if="widthWindow > 500" class="special__title h1">Специальные предложения и события</div>
          <!--div class="special__btn-wrap">
            <div :class="{'btn': !isSpecialClass}" @click="isSpecialClass = false" class="">Специальные предложения</div>
            <div :class="{'btn': isSpecialClass}" @click="isSpecialClass = true">Афиша</div>
          </div-->
          <transition-group name="v-transition-special">
            <div :key="1" v-if="!isSpecialClass && widthWindow > 500" class="special__container">
              <!--div class="image-container">
                <div class="special__img-first">
                  <p class="special__img-title">Мечтайте заранее -15% на июль и август</p>
                  <img src="../assets/img/Home/special/img1.jpg" alt="">
                </div>
                <div class="special__img-last">
                  <p class="special__img-title">Каждая 3-я ночь в подарок</p>
                  <img src="../assets/img/Home/special/img2.jpg" alt="">
                </div>
              </div-->
              <div class="image-container">
                <div class="special__img">
                  <p class="special__img-title">Приглашаем Вас провести осенние каникулы в отеле «АБААТА».</p>
                  <img src="../assets/img/Home/special/img3.jpg" alt="">
                </div>
              </div>
              <!--div class="image-container">
                <div class="special__img-first">
                  <p class="special__img-title">Получайте кэшбэк до 20 000 руб</p>
                  <img src="../assets/img/Home/special/img4.jpg" alt="">
                </div>
                <div class="special__img-last">
                  <p class="special__img-title">Раннее бронирование - выгода 10%</p>
                  <img src="../assets/img/Home/special/img5.jpg" alt="">
                </div>
              </div-->
            </div>
            <!--div :key="2" v-if="isSpecialClass && widthWindow > 500" class="special__container-poster">
              <div class="wrap">
                <div class="poster-container">
                  <div class="poster-container_img">
                    <img src="../assets/img/Home/special/afisha/img1.jpg" alt="">
                  </div>
                  <div class="poster-container_text">
                    <div class="title h3">Групповые экскурсии</div>
                    <div class="vertical-hr"></div>
                    <div class="days">
                      <p>09<br>Мар</p>
                      <p>31<br>Дек</p>
                    </div>
                  </div>
                  <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
                </div>
                <div class="poster-container">
                  <div class="poster-container_img">
                    <img src="../assets/img/Home/special/afisha/img2.jpg" alt="">
                  </div>
                  <div class="poster-container_text">
                    <div class="title h3">Крымские каникулы: pre-party лета 2021</div>
                    <div class="vertical-hr"></div>
                    <div class="days">
                      <p>09<br>Мар</p>
                      <p>31<br>Дек</p>
                    </div>
                  </div>
                  <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
                </div>
                <div class="poster-container">
                  <div class="poster-container_img">
                    <img src="../assets/img/Home/special/afisha/img3.jpg" alt="">
                  </div>
                  <div class="poster-container_text">
                    <div class="title h3">День рождения Mriya Resort&Spa</div>
                    <div class="vertical-hr"></div>
                    <div class="days">
                      <p>09<br>Мар</p>
                      <p>31<br>Дек</p>
                    </div>
                  </div>
                  <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
                </div>
                <div class="poster-container">
                  <div class="poster-container_img">
                    <img src="../assets/img/Home/special/afisha/img4.jpg" alt="">
                  </div>
                  <div class="poster-container_text">
                    <div class="title h3">Закрытие пляжного сезона</div>
                    <div class="vertical-hr"></div>
                    <div class="days">
                      <p>09<br>Мар</p>
                      <p>31<br>Дек</p>
                    </div>
                  </div>
                  <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
                </div>
              </div>
              <div class="btn">Посмотреть все собития</div>
            </div-->
          </transition-group>
          <div v-if="widthWindow < 500">
            <div class="carousel__arrow-nav">
              <div style="left: 10px" @click="showPrev('carousel2')" class="arrow-nav-prev pointer">
                <img src="../assets/img/Home/arrowPrev1.svg" alt="">
              </div>
              <div style="right: 5px" @click="showNext('carousel2')" class="arrow-nav-next pointer">
                <img src="../assets/img/Home/arrowNext1.svg" alt="">
              </div>
            </div>
          </div>
          <Carousel v-if="widthWindow < 500 && !isSpecialClass" class="carousel-container"
                     v-bind="settings1"
                     ref="carousel2"
          >
            <div class="special-slide">
              <div class="special-slide-title">Мечтайте заранее -15% на июль и август</div>
              <div class="special-slide-img">
                <img src="../assets/img/Home/img1.jpg" alt="">
              </div>
            </div>
            <div class="special-slide">
              <div class="special-slide-title">Мечтайте заранее -15% на июль и август</div>
              <div class="special-slide-img">
                <img src="../assets/img/Home/img1.jpg" alt="">
              </div>
            </div>
            <template #prevArrow="arrowOption">
              <div class="custom-arrow">
                <input hidden type="text" :value="sliderActive2 = arrowOption.currentSlide">
              </div>
            </template>
            <template style="display: none" #nextArrow="arrowOption">
              <div class="custom-arrow" style="display: none">
                {{arrowOption.currentSlide}}
              </div>
            </template>
          </Carousel>
          <Carousel v-if="widthWindow < 500 && isSpecialClass" class="carousel-container"
                     v-bind="settings1"
                     ref="carousel"
          >
            <div class="special-slide">
              <div class="poster-container">
                <div class="poster-container_img">
                  <img src="../assets/img/Home/special/afisha/img1.jpg" alt="">
                </div>
                <div class="poster-container_text">
                  <div class="title h3">Групповые экскурсии</div>
                  <div class="vertical-hr"></div>
                  <div class="days">
                    <p>09<br>Мар</p>
                    <p>31<br>Дек</p>
                  </div>
                </div>
                <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
              </div>
            </div>
            <div class="special-slide">
              <div class="poster-container">
                <div class="poster-container_img">
                  <img src="../assets/img/Home/special/afisha/img2.jpg" alt="">
                </div>
                <div class="poster-container_text">
                  <div class="title h3">Крымские каникулы: pre-party лета 2021</div>
                  <div class="vertical-hr"></div>
                  <div class="days">
                    <p>09<br>Мар</p>
                    <p>31<br>Дек</p>
                  </div>
                </div>
                <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
              </div>
            </div>
            <div class="special-slide">
              <div class="poster-container">
                <div class="poster-container_img">
                  <img src="../assets/img/Home/special/afisha/img3.jpg" alt="">
                </div>
                <div class="poster-container_text">
                  <div class="title h3">День рождения Mriya Resort&Spa</div>
                  <div class="vertical-hr"></div>
                  <div class="days">
                    <p>09<br>Мар</p>
                    <p>31<br>Дек</p>
                  </div>
                </div>
                <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
              </div>
            </div>
            <div class="special-slide">
              <div class="poster-container">
                <div class="poster-container_img">
                  <img src="../assets/img/Home/special/afisha/img4.jpg" alt="">
                </div>
                <div class="poster-container_text">
                  <div class="title h3">Закрытие пляжного сезона</div>
                  <div class="vertical-hr"></div>
                  <div class="days">
                    <p>09<br>Мар</p>
                    <p>31<br>Дек</p>
                  </div>
                </div>
                <img src="../assets/img/Home/special/time.svg" alt="" class="img-time">
              </div>
            </div>
            <template #prevArrow="arrowOption">
              <div class="custom-arrow">
                <input hidden type="text" :value="sliderActive3 = arrowOption.currentSlide">
              </div>
            </template>
            <template style="display: none" #nextArrow="arrowOption">
              <div class="custom-arrow" style="display: none">
                {{arrowOption.currentSlide}}
              </div>
            </template>
          </Carousel>
        </div>
      </div>
    </section>
    <!--section class="conf">
      <div class="title">Проведите деловое мероприятие в роскошной обстановке просторных конференц-залов</div>
      <div class="navigation">
        <div :class="{'slide-active': sliderActiveConf === 0}" @click="goToConf(0)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/01.png" alt="">
        </div>
        <div :class="{'slide-active': sliderActiveConf === 1}" @click="goToConf(1)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/02.png" alt="">
        </div>
        <div :class="{'slide-active': sliderActiveConf === 2}" @click="goToConf(2)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/03.png" alt="">
        </div>
        <div :class="{'slide-active': sliderActiveConf === 3}" @click="goToConf(3)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/04.png" alt="">
        </div>
        <div :class="{'slide-active': sliderActiveConf === 4}" @click="goToConf(4)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/05.png" alt="">
        </div>
        <div :class="{'slide-active': sliderActiveConf === 5}" @click="goToConf(5)" class="navigation__container">
          <img src="../assets/img/Home/conf/navigation/06.png" alt="">
        </div>
      </div>
      <Carousel class="carousel-container"
                v-bind="settingsConf"
                ref="carousel-conf"
      >
        <div :class="{'slide-active': sliderActiveConf === 0}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <div :class="{'slide-active': sliderActiveConf === 1}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <div :class="{'slide-active': sliderActiveConf === 2}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <div :class="{'slide-active': sliderActiveConf === 3}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <div :class="{'slide-active': sliderActiveConf === 4}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <div :class="{'slide-active': sliderActiveConf === 5}" class="slide"><img src="../assets/img/Home/conf/img1.png" alt=""></div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            <input hidden type="text" :value="sliderActiveConf = arrowOption.currentSlide">
          </div>
        </template>
        <template style="display: none" #nextArrow="arrowOption">
          <div class="custom-arrow" style="display: none">
            {{arrowOption.currentSlide}}
          </div>
        </template>
      </Carousel>
    </section-->
    <section class="gallery" v-if="galleries.length > 0 && $root.site === 'abaata'">
      <div class="container">
        <div class="title h1">Галерея</div>
        <div v-if="widthWindow > 500" class="gallery__wrap">
          <router-link tag="div" :to="'/'+$root.site+'/gallery/'+gallery.id" class="gallery__container" v-for="gallery in galleries" v-if="gallery.id !== 70" :key="gallery.id">
            <div class="wrap-img">
              <img :src="gallery.cf[138][0]"  alt="" style="width: auto; height: auto; max-width: 370px; max-height: 246px;">
              <p class="btn-white">{{ gallery.name }}</p>
            </div>
            <div class="subtitle">{{ gallery.short_html }}</div>
          </router-link>
        </div>
      </div>

      <div class="special__wrap"  v-if="widthWindow < 500">
        <div>
          <div class="carousel__arrow-nav">
            <div style="left: 10px" @click="showPrev('carousel3')" class="arrow-nav-prev pointer">
              <img src="../assets/img/Home/arrowPrev1.svg" alt="">
            </div>
            <div style="right: 5px" @click="showNext('carousel3')" class="arrow-nav-next pointer">
              <img src="../assets/img/Home/arrowNext1.svg" alt="">
            </div>
          </div>
        </div>

        <Carousel v-if="!isSpecialClass" class="carousel-container"
                  v-bind="settings1"
                  ref="carousel3"
        >


          <router-link tag="div" :to="'/'+$root.site+'/gallery/'+gallery.id" class="special-slide" v-for="gallery in galleries" v-if="gallery.id !== 70" :key="gallery.id">
            <div class="special-slide-title">{{ gallery.name }}</div>
            <div class="special-slide-img">
              <img :src="gallery.cf[138][0]" alt="" style="width: auto; height: auto; max-width: 370px; max-height: 246px;">
            </div>
          </router-link>

          <template #prevArrow="arrowOption">
            <div class="custom-arrow">
              <input hidden type="text" :value="sliderActive2 = arrowOption.currentSlide">
            </div>
          </template>
          <template style="display: none" #nextArrow="arrowOption">
            <div class="custom-arrow" style="display: none">
              {{arrowOption.currentSlide}}
            </div>
          </template>
        </Carousel>
      </div>
      <!--div class="container">
        <div class="btn">Показать еще</div>
      </div-->
    </section>
    <section class="gallery" v-if="galleries.length > 0 && $root.site === 'picunda'">
      <div class="container">
        <div class="title h1">Галерея</div>
        <div v-if="widthWindow > 500" class="gallery__wrap">
          <router-link tag="div" :to="'/'+$root.site+'/gallery/'+gallery.id" class="gallery__container" v-for="gallery in galleries" :key="gallery.id">
            <div class="wrap-img">
              <img :src="gallery.cf[663][0]"  alt="" style="width: auto; height: auto; max-width: 370px; max-height: 246px;">
              <p class="btn-white">{{ gallery.name }}</p>
            </div>
            <div class="subtitle">{{ gallery.short_html }}</div>
          </router-link>
        </div>
      </div>

      <div class="special__wrap"  v-if="widthWindow < 500">
        <div>
          <div class="carousel__arrow-nav">
<!--            <div style="left: 10px" @click="showPrev('carousel3')" class="arrow-nav-prev pointer">
              <img src="../assets/img/Home/arrowPrev1.svg" alt="">
            </div>
            <div style="right: 5px" @click="showNext('carousel3')" class="arrow-nav-next pointer">
              <img src="../assets/img/Home/arrowNext1.svg" alt="">
            </div>-->
          </div>
        </div>

        <Carousel v-if="!isSpecialClass" class="carousel-container"
                  v-bind="settings1"
                  ref="carousel3"
        >


          <router-link tag="div" :to="'/'+$root.site+'/gallery/'+gallery.id" class="special-slide" v-for="gallery in galleries" v-if="gallery.id !== 70" :key="gallery.id">
            <div class="special-slide-title">{{ gallery.name }}</div>
            <div class="special-slide-img">
              <img :src="gallery.cf[663][0]" alt="" style="width: auto; height: auto; max-width: 370px; max-height: 246px;">
            </div>
          </router-link>

          <template #prevArrow="arrowOption">
            <div class="custom-arrow">
              <input hidden type="text" :value="sliderActive2 = arrowOption.currentSlide">
            </div>
          </template>
          <template style="display: none" #nextArrow="arrowOption">
            <div class="custom-arrow" style="display: none">
              {{arrowOption.currentSlide}}
            </div>
          </template>
        </Carousel>
      </div>
      <!--div class="container">
        <div class="btn">Показать еще</div>
      </div-->
    </section>
    <!--section class="reviews">
      <div class="container">
        <div class="reviews__title">Отзывы</div>
      </div>
    </section-->
    <section class="abaata"  v-if="$root.site === 'abaata'">
      <div class="container">
        <div class="abaata__title">
          Абаата - абхазский курорт премиум-класса на берегу Чёрного моря
        </div>
        <div class="abaata__wrap">
          <p>
            Отель «Абаата» - современный продолжатель традиций гостеприимства, заложенных в начале 20 века Принцем Ольденбургским при постройке великосветского черноморского курорта на территории Гагрской средневековой крепости Абаата.
          </p>
          <p>
            Сегодня отель состоит из трёх корпусов - 1903, 1906 и 2009 годов постройки. Постройка 1903 года - трехэтажная "Приморская гостиница" и постройка 1906 года - Меблированные комнаты "Принц Ольденбург" были реконструированы в 2015 году и приобрели свой исторический вид, при этом современный интерьер и комфортабельные номера соответствуют европейским стандартам гостиничного сервиса.
          </p>
          <p>
            Отель располагает собственным пляжем с бесплатными зонтиками, полотенцами и лежаками, и парком, в котором собраны различные тропические и субтропические деревья и кустарники.
          </p>
        </div>
      </div>
    </section>
    <section class="abaata"  v-if="$root.site === 'picunda'">
      <div class="container">
        <div class="abaata__title">
          Абаата - абхазский курорт премиум-класса на берегу Чёрного моря
        </div>
        <div class="abaata__wrap">
          <p>
            Отель «Абаата Пицунда» продолжатель концепции и идеи гостеприимства отеля исторического отеля «Абаата Гагра» запущен в 2022 году. Расположен в живописном месте в окружении природного ландшафта на въезде в г. Пицунда на берегу Чёрного моря с собственным пляжем протяжённостью 300 м. Архитектура 5-и этажного здания и интерьеры выдержаны в стиле современного лофта с использованием природных материалов и мебели из массива кавказского каштана. На охраняемой территории отеля площадью более 5 га расположен пляжный бар, оборудованный пляж с бесплатным инвентарем, футбольный стадион (100х50 м), площадка для пляжного волейбола , открытый бассейн (25х10 м) и бесплатная охраняемая парковка.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Calendar from '../components/Calendar2'
// https://www.npmjs.com/package/vue-slick-carousel
import Carousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Calendar,
    Carousel,
  },
  data () {
    return {
      settings: {
        "infinite": true,
        // arrows: false,
        focusOnSelect: true,
        "centerMode": true,
        "centerPadding": "20px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true,
        initialSlide: 0,
        currentSlide: null
      },
      settings1: {
        "infinite": true,
        // arrows: false,
        focusOnSelect: true,
        "centerMode": true,
        "centerPadding": "0px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": false,
        initialSlide: 0,
        currentSlide: null
      },
      settingsConf: {
        "infinite": true,
        // arrows: false,
        focusOnSelect: true,
        "centerMode": true,
        "centerPadding": "0px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true,
        initialSlide: 0,
        currentSlide: null
      },
      sliderActive: 0,
      sliderActiveCategory: 0,
      sliderActive2: 0,
      sliderActive3: 0,
      sliderActive4: 0,
      sliderActiveConf: 0,
      isSpecialClass: false,
      widthWindow: false,
      categories: {},
      restaurants: {},
      services: {},
      galleries: {}
    }
  },
  methods: {
    showNext (id) {
      this.$refs[id].next()
    },
    showPrev (id) {
      this.$refs[id].prev()
    },
    goTo (e) {
      this.sliderActiveCategory = e
      this.$refs['carousel1'].goTo(e)
    },
    goToConf (e) {
      this.sliderActiveConf = e
      this.$refs['carousel-conf'].goTo(e)
    },
    mobileWindow () {
      const screenWidth = window.screen.width
      this.widthWindow = screenWidth
      console.log(this.widthWindow)
    },
  },
  mounted() {
    if(this.$root.site === 'abaata') {

      axios.get('https://api.abaata.ru/list/get-numbers').then(response => {
        this.categories = response.data
        this.$forceUpdate()
      })



      axios.get('https://api.abaata.ru/list/get-lists?id=4&site=1').then(response => {
        this.restaurants = response.data
        this.$forceUpdate()
      })
      axios.get('https://api.abaata.ru/list/get-lists?id=3&site=1').then(response => {
        this.services = response.data
        this.$forceUpdate()
      })
      axios.get('https://api.abaata.ru/list/get-lists?id=5&site=1').then(response => {
        this.galleries = response.data
        this.$forceUpdate()
      })
    } else {
      axios.get('https://api.abaata.ru/list/get-numbers-p').then(response => {
        this.categories = response.data
        this.$forceUpdate()
      })

      axios.get('https://api.abaata.ru/list/get-lists?id=50&site=1').then(response => {
        this.galleries = response.data
        this.$forceUpdate()
      })
    }

    this.mobileWindow()
  }
}
</script>
<style lang="scss">
  .home {
    background-color: dimgray;
    .home__head {
      width: 100%;
      height: 100vh;
      background: url("../assets/img/new/main.jpg") center no-repeat;
      background-size: cover;
      position: relative;
      background-position-x: right;
      .home__head-logo {
        position: absolute;
        top: calc(40% - 150px);
        left: calc(50% - 160px);
        width: 320px;
        z-index: 10;
        .newYear-central-cap{
          position: absolute;
          top:0;
          right: -35px
        }
        .newYear-central-cap-abaata{
          position: absolute;
          top:10px;
          right: -35px
        }
        .newYear-central-logo{

        }

        img {
          width: 100%;
        }
        .home__head-logo_title {
          margin-top: 48px;
          color: white;
          text-align: center;
          letter-spacing: initial;
        }
        .calendar-wrap {
          display: flex;
        }
      }
      .home__head-footer {
        position: absolute;
        bottom: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        color: #ffffff;
        z-index: 10;
        .left {
          display: flex;
          .left__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 15px;text-align: center;
          }
          p {
            margin-top: 8px;
          }
        }
        .middle {
          text-align: center;
          .scroll-link {
            color: #ffffff;
            .scroll-link__icon {
              position: relative;
              display: block;
              width: 1.25rem;
              height: 2.3125rem;
              border: 1px solid #fff;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: .3125rem;
              border-radius: 20px;
              &::after {
                content: '';
                position: absolute;
                top: .625rem;
                left: 50%;
                width: .3125rem;
                height: .5rem;
                margin-left: -.125rem;
                border-radius: 3px;
                background-color: #fff;
                -webkit-animation: mouseWheel 1.3s infinite;
                animation: mouseWheel 1.3s infinite;
                animation-duration: 1.3s;
                animation-timing-function: ease;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                animation-direction: normal;
                animation-fill-mode: none;
                animation-play-state: running;
                animation-name: mouseWheel;
              }
            }
          }
        }
        .right {
          display: flex;
          .right__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 15px;
            text-align: center;
          }
          p {
            margin-top: 8px;
          }
        }
      }
    }
    .carousel {
      background: #625948;
      position: relative;
      padding-bottom: 40px;
      @media (max-width: 768px) {
        max-height: 100vh;
        overflow: hidden;
      }
      @media (max-width: 428px) {
        max-height: initial;
        overflow: hidden;
        padding-bottom: initial;
      }
      .carousel__head-wrap {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        .carousel__head-container {
          color: rgba(255, 255, 255, 0.25);
          position: relative;
          margin: 0 10px;
          transition: all 0.5s;
          &:before {
            content: '';
            position: absolute;
            top: -10px;
            width: calc(100% + 20px);
            transform: translateX(-10px);
            border-top: 1px solid rgba(255, 255, 255, 0.25);
          }
          &.slide-active {
            color: #ffffff;
            &:before {
              content: '';
              position: absolute;
              top: -10px;
              width: calc(100% + 20px);
              transform: translateX(-10px);
              border-top: 1px solid #ffffff;
            }
          }
        }
      }
      .carousel__arrow-nav {
        @media (max-width: 768px) {
          display: none;
        }
        .arrow-nav-prev,
        .arrow-nav-next {
          position: absolute;
          top: calc(50% + 55px);
          left: 10vw;
          width: 52px;
          z-index: 11;
          &.__disable {
            cursor: no-drop;
          }
        }
        .arrow-nav-next {
          left: initial;
          right: 10vw;
        }
      }
      .carousel-container {
        .carousel-slide {
          margin: 0 30px;
          color: #ffffff;
          opacity: 0.3;
          &.slide-active {
            opacity: 1;
          }
          .slide-wrap {
            display: flex;
            flex-direction: column;
            max-width: 100vw;
            .slide__title {
              transform: translateX(12px);
              margin-bottom: 32px;
              margin-top: 40px;
              text-transform: uppercase;
            }
            .slide-content {
              width: 1000px;
              display: flex;
              flex-wrap: wrap;
              @media (max-width: 768px) {
                width: 768px;
              }
              .slide__image-wrap {
                position: relative;
                flex-basis: 30%;
                margin: 10px;
                @media (max-width: 768px) {
                  flex-basis: initial;
                }
                .overflow-container {
                  overflow: hidden;
                  &:hover img {
                    transform: scale(1.1);
                  }
                  img {
                    width: 310px;
                    height: 310px;
                    transition: all 0.5s;
                    pointer-events: none;
                    @media (max-width: 768px) {
                      width: 228px;
                      height: 228px;
                    }
                  }
                }
                .slide__image-subtitle {
                  position: absolute;
                  bottom: 30px;
                  left: 30px;
                }
              }
            }
          }
        }
      }
      .carousel-mob__wrap {
        margin-top: 20px;
        .carousel-mob__container {
          border-top: 1px solid #ffffff;
          .carousel-mob__container-tit {
            font-size: 28px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            color: #ffffff;
            padding: 20px 0;
          }
          .carousel-mob__container-img {
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .special, .gallery {
      background: white;
      padding: 40px 0;
      .special__wrap {
        color: #625948;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        position: relative;
        .special__title {  }
        .special__btn-wrap {
          max-width: initial;
          display: inline-flex;
          font-weight: bold;
          align-items: center;
          margin-top: 42px;
          margin-bottom: 40px;
          height: 45px;
          .btn {
            cursor: context-menu;
            color: #625948;
          }
          div {
            color: black;
            margin-left: 20px;
            cursor: pointer;
            transition: all 0.5s;
            &:hover {
              border-bottom: 1px solid #000000;
            }
          }
        }
        .carousel-container {
          width: 100%;
          .special-slide {
            position: relative;
            .special-slide-title {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 22px;
              line-height: 24px;
              color: #FFFFFF;
              text-align: center;
            }
            .special-slide-img {
              img {
                width: 100%;
              }
            }
            .poster-container {
              background: #FFFFFF;
              border: 1px solid #D3C8B2;
              box-sizing: border-box;
              position: relative;
              .poster-container_img {
                img {width: 100%;
                  @media (max-width: 768px) {
                    width: 100%;
                  }
                }
              }
              .poster-container_text {
                display: flex;
                padding: 20px;
                padding-bottom: 60px;
                .title {
                  max-width: 70%;
                  min-width: 55%;
                  margin-right: 5px;
                  color: #000000;
                }
                .days {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  padding-left: 10px;
                  max-width: 45%;
                  min-width: 30%;
                }
              }
              .img-time {
                position: absolute;
                bottom: 20px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .conf {
      width: 100%;
      height: 100vh;
      position: relative;
      @media (max-width: 768px) {
        overflow: hidden;
        height: 500px;
      }
      .title {
        font-size: 48px;
        line-height: 53px;
        position: absolute;
        max-width: 770px;
        top: calc(50% - 30px);
        left: calc(50% - 380px);
        text-align: center;
        z-index: 10;
        color: white;
        @media (max-width: 500px) {
          font-size: 28px;
          line-height: 33px;
          width: 100%;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .navigation {
        display: flex;
        position: absolute;
        bottom: 40px;
        left: 135px;
        z-index: 10;
        @media (max-width: 768px) {
          left: 92px;
        }
        @media (max-width: 500px) {
          width: 100%;
          top: 85%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .navigation__container {
          cursor: pointer;
          width: 16.666%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 3px solid transparent;
          min-height: 35px;
          max-height: 70px;
          height: 12vw;
          display: inline-block;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-transition: all .2s ease;
          transition: all .2s ease;
          img {
            max-width: 100%;
          }
          &.slide-active {
            border: 1px solid #ffffff;
            transform: scale(1.08);
          }
        }
      }
      .slide {
        img {
          width: 100%;
          max-width: 100%;
          height: 100vh;
          pointer-events: none;
          @media (max-width: 768px) {
            width: 768px;
            height: 500px;
          }
        }
      }
    }
    .reviews {
      background: #625948;
      padding: 40px 0;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .reviews__title {
          font-size: 36px;
          line-height: 40px;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          margin-bottom: 40px;
          color: #ffffff;
        }
      }
    }
    .abaata {
      background: white;
      padding: 40px 0;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .abaata__title {
          font-size: 36px;
          line-height: 40px;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          margin-bottom: 40px;
          color: #917540;
          text-align: center;
        }
        .abaata__wrap {
          p {
            margin: 10px 0;
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
    }
    .arrow-nav-prev,
    .arrow-nav-next {
      position: absolute;
      top: calc(50% + 55px);
      left: 10vw;
      width: 52px;
      z-index: 11;
      &.__disable {
        cursor: no-drop;
      }
    }
    .arrow-nav-next {
      left: initial;
      right: 10vw;
    }
  }
  .special__container {
    max-width: 100%;
    flex-wrap: wrap;
    .image-container {
      display: flex;
      margin-bottom: 30px;
      .special__img-first,.special__img {
        margin-right: 30px;
        position: relative;
        color: #ffffff;
        font-weight: 600;
        overflow: hidden;
        .special__img-title {
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 2;
        }
        img {
          transition: all 0.5s;
          max-width: 100%;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .special__img-last {
        position: relative;
        color: #ffffff;
        font-weight: 600;
        overflow: hidden;
        .special__img-title {
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 2;
        }
        img {
          transition: all 0.5s;
          max-width: 100%;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .special__container-poster {
    display: flex;
    flex-direction: column;
    align-items: center;
    .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .poster-container {
        background: #FFFFFF;
        border: 1px solid #D3C8B2;
        box-sizing: border-box;
        position: relative;
        max-width: 370px;
        margin-bottom: 15px;
        margin-right: 10px;
        @media (max-width: 768px) {
          max-width: 320px;
        }
        .poster-container_img {
          img {
            @media (max-width: 768px) {
              max-width: 100%;
            }
          }
        }
        .poster-container_text {
          display: flex;
          padding: 20px;
          padding-bottom: 60px;
          .title {
            max-width: 70%;
            min-width: 55%;
            margin-right: 5px;
            color: #000000;
          }
          .days {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-left: 10px;
            max-width: 45%;
            min-width: 30%;
          }
        }
        .img-time {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }
    }
    .btn {
      display: inline-flex;
      margin: 20px 0;
    }
  }
  @media (max-width: 768px) {
    .special__container {
      .image-container {
        margin-bottom: 20px;
        .special__img-first, .special__img {
          margin-right: 20px;
          img {
          }
        }
      }
    }
  }
  .gallery {
    padding: 40px 0;
    background: #ffffff;
    @media (max-width: 500px) {
      position: relative;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 40px; color: #917540; text-transform: uppercase;
      }
      .gallery__wrap {
        display: flex;
        flex-wrap: wrap;
      }
      .btn {
        margin-top: 45px;
        color: #625948;
        font-weight: bold;
      }
    }
    .gallery__container {
      max-width: 370px;
      margin: 10px;
      @media (max-width: 768px) {
        max-width: 228px;
        margin: 3px;
      }
      .wrap-img {
        position: relative;
        img {
          @media (max-width: 768px) {
            max-width: 228px;
          }
        }
        p {
          position: absolute;
          bottom: 15px;
          left: 15px;
          font-size: 12px;
          line-height: 13px;
          text-transform: uppercase;
        }
        .btn-white {
          padding: 5px 9px;
        }
      }
      .subtitle {
        margin-top: 16px; color: #000000;font-size: 16px;
        line-height: 18px;
      }
    }
    .gallery-slide {
      .gallery__container {
        position: relative;
        width: 100%;
        max-width: initial;
        margin: initial;
        img {
          width: 100%;
        }
        .subtitle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          color: #ffffff;
          max-width: 85%;
        }
      }
    }
  }
  video{display:inline-block;vertical-align:baseline;}
  .bg-video{position:absolute;top:0;right:0;bottom:0;left:-5px;}
  .bg-video__media{-webkit-transform:translate(-50%,-50%);}
  .bg-video{z-index:1;display:none;overflow:hidden;}
  .bg-video_visible{display:block;opacity: 0.4;background-color: black;}
  .bg-video__media{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);min-height:100%;min-width:100%;width:auto;height:auto;}
  ::-webkit-input-placeholder{color:#f4f4f4;opacity:.38;}
  :-moz-placeholder{color:#f4f4f4;opacity:.38;}
  ::-moz-placeholder{color:#f4f4f4;opacity:.38;}
  :-ms-input-placeholder{color:#f4f4f4;opacity:.38;}
  .v-transition-special-enter {
    opacity: 0;
  }
  .v-transition-special-enter-active {
    transition: all 1s;
  }
  .v-transition-special-enter-to {
    opacity: 1;
  }
  @-webkit-keyframes mouseWheel {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      opacity: 0;
    }
  }
  @keyframes mouseWheel {
       0% {
         -webkit-transform: translateY(0);
         transform: translateY(0);
         opacity: 1;
       }
       100% {
         -webkit-transform: translateY(100%);
         transform: translateY(100%);
         opacity: 0;
       }
  }
  @media screen and (max-width: 700px){
    .newYear-central-logo{
      width: 290px!important;
      height: 98px;
    }
    .home .home__head .home__head-logo .newYear-central-cap{
      width: 32px;
      height: 22px;
      top:5px;
      right: 25px
    }
    .home .home__head .home__head-logo .newYear-central-cap-abaata{
      width: 32px;
      height: 22px;
      top:15px;
      right:32px
    }
  }
</style>
<style>
.carousel-container .gallery-slide {
  position: relative;
}
.home .home__head.picundas {
  background: url('https://img1.abaata.ru/uploads/1669318670_picunda.jpg.jpg') center no-repeat;
  background-size: auto;
  background-size: cover;
  background-position-x: right;
}

@media (max-width: 500px) {
  .home .home__head.picundas {
    background-position-x: right;
  }
}
</style>
