<template>
  <div v-click-outside="hideCalendar" class="calendar">
    <div class="calendar__container">
      <div class="calendar__container-left">Заезд</div>
      <div  class="calendar__popup-wrap">
        <input type="date" class="calendar__popup-wrap-span" v-model="start" style="background: transparent">
        <img src="../assets/img/Home/calendarik.svg" alt="">
      </div>
    </div>
    <div class="calendar__container">
      <div class="calendar__container-left">Выезд</div>
      <div  class="calendar__popup-wrap">
        <input type="date" class="calendar__popup-wrap-span" v-model="end" style="background: transparent">
        <img src="../assets/img/Home/calendarik.svg" alt="">
      </div>
    </div>
    <div class="calendar__container">
      <div class="calendar__container-left">Гости</div>
      <div @click="isGuests = true" class="calendar__popup-wrap">
        <span class="calendar__popup-wrap-span">{{parseInt(quantityGuests)+parseInt(quantityChildren)}}</span>
        <img src="../assets/img/Home/guests.svg" alt="">
        <div v-if="isGuests" ref="guests-popup" class="calendar__guest-popup">
          <div class="calendar__guest-popup-container" style="height: unset; min-height: unset;top: 60px;right: 10px;">
            <div class="calendar__guests-header">
              <div class="calendar__guests-title">Количество гостей</div>
            </div>
            <div class="calendar__guests-groups">
              <div class="calendar__guests-group" style="display:none;">
                <div class="calendar__guests-group-header">
                  <div class="calendar__guests-group-room">НОМЕР <span class="calendar__guests-room-num">1</span></div>
                </div>
              </div>
              <div class="calendar__guests-group-select" style="border: none; padding-bottom: 0">
                <div class="calendar__guests-adults">
                  <div class="calendar__guests-adults-label">Взрослые</div>
                  <div class="calendar__guests-adults-control">
                    <div>
                      <div :class="{'__no-drop': quantityGuests === 1}" @click="decrementItem" class="calendar__input-step minus"></div>
                      <input type=" text" maxlength="2" pattern="[0-9]*" inputmode="numeric" v-model="quantityGuests" required=" true" class="calendar__input">
                      <div @click="incrementItem" class="calendar__input-step plus"></div>
                    </div>
                  </div>
                </div>
                <div class="calendar__guests-adults">
                  <div class="calendar__guests-adults-label">Дети младше 12 лет</div>
                  <div class="calendar__guests-adults-control">
                    <div>
                      <div :class="{'__no-drop': quantityChildren === 0}" @click="decrementChildren" class="calendar__input-step minus"></div>
                      <input type=" text" maxlength="2" pattern="[0-9]*" inputmode="numeric" v-model="quantityChildren" required=" true" class="calendar__input">
                      <div @click="incrementChildren" class="calendar__input-step plus"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="calendar__guests-controls">
              <div class="calendar__guests-btn-container plus" style="display:none;">+ Добавить еще номер</div>
              <div @click="isGuests = false" class="calendar__guests-btn-container" style="width: 100%;height: 40px">Готово</div>
            </div>
            <div class="calendar__loader"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar__container __btn">
      <div class="calendar__popup-wrap __btn">
        <span class="calendar__popup-wrap-span" @click="sendRequest">Найти номер</span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment'
export default {
  name: "Calendar",
  data () {
    return {
      isCalendarLap: false,
      isCalendarExit: false,
      isGuests: false,
      start: null,
      end: null,
      quantityGuests: 1,
      quantityChildren: 0,
    }
  },
  mounted() {
    this.init()

      var date = new Date()
      var m = date.getMonth()
      var d = date.getDate()
      m++
      if (m < 10) m = '0' + m
      if (d < 10) d = '0' + d
    if(!this.$route.params.start) this.start = date.getFullYear() + '-' + m + '-' + d


    date = new Date()
    date.setDate(date.getDate() + 7);
    m = date.getMonth()
    d = date.getDate()
    m++
    if(m < 10) m = '0'+m
    if(d < 10) d = '0'+d
    if(!this.$route.params.end) this.end = date.getFullYear() +'-'+ m +'-'+ d
  },
  methods: {
    sendRequest () {
      var dates = 0
      var checkStart = 0
      var checkEnd = 0
      if(this.start && this.end) {
        var start = moment(this.start);
        var end = moment(this.end);
        dates = end.diff(start, "days")
      }
      if(this.start) {
        var start = moment();
        var end = moment(this.start);
        checkStart = end.diff(start, "days")
      }
      if(this.end) {
        var start = moment();
        var end = moment(this.end);
        checkEnd = end.diff(start, "days")
      }
      console.log(checkStart)
      console.log(checkEnd)
      console.log(parseInt(this.quantityGuests))
      if(!this.start) alert('Укажите дату начала поездки')
      else if(!this.end) alert('Укажите дату окончания поездки')
      else if(parseInt(this.quantityGuests)<=0) alert('Укажите количество взрослых')
      else if(dates<=0 || checkStart < 0 || checkEnd <0 || checkEnd < checkStart) alert('Вы указали неверный промежуток времени поездки')
      else {
        this.$router.push({
          name: 'booking', params: {
            site: this.$root.site,
            start: this.start,
            end: this.end,
            guests: parseInt(this.quantityGuests),
            children: parseInt(this.quantityChildren)
          }
        })
      }
    },
    showCalendar (e) {
      if (e === 'Заезд') this.isCalendarLap = true
      if (e === 'Выезд') this.isCalendarExit = true
    },
    hideCalendar () {
      // пользовательская дирректива v-click-outside находится в main.js
      this.isCalendarExit = false
      this.isCalendarLap = false
      this.isGuests = false
    },
    decrementItem () {
      if (this.quantityGuests > 1) {
        return this.quantityGuests -= 1
      } else {
        return false
      }
    },
    incrementItem () {
      if (this.quantityGuests >= 1) {
        return this.quantityGuests += 1
      } else {
        return false
      }
    },
    decrementChildren () {
      if (this.quantityChildren >= 1) {
        return this.quantityChildren -= 1
      } else {
        return false
      }
    },
    incrementChildren () {
      if (this.quantityChildren >= 0) {
        return this.quantityChildren += 1
      } else {
        return false
      }
    },
    init () {
      if(this.$route.params.start) this.start = this.$route.params.start
      if(this.$route.params.end) this.end = this.$route.params.end
      if(this.$route.params.guests) this.quantityGuests = this.$route.params.guests
      if(this.$route.params.children) this.quantityChildren = this.$route.params.children
    }
  },
  watch: {
    $route () {
      this.init()
    }
  }
}
</script>
<style>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}
/* To remove default blue outline */
input[type='date']:focus {
  outline: none;
}

/*  To remove cross sign */
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
}

/* To remove Spin Arrows */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

</style>
<style lang="scss" scoped>
.calendar {
  position: absolute;
  left: calc(50% - 425px);
  top: 55%;
  display: flex;
  z-index: 100000;
  @media (max-width: 768px) {
    left: calc(50% - 320px);
  }
  @media (max-width: 500px) {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .calendar__container {
    border: 2px solid #fff;
    float: left;
    width: 230px;
    margin: 3px 4px 0 0;
    vertical-align: baseline;
    background: transparent;
    position: relative;
    @media (max-width: 768px) {
      width: 160px;
    }
    @media (max-width: 500px) {
      width: 85%;
      margin: 3px 0;
      &:first-child {
        margin-top: 20px;
      }
    }
    &.__btn {
      max-width: 136px;
      @media (max-width: 500px) {
        max-width: 85%;
      }
    }
    .calendar__popup-wrap {
      width: calc(100% - 70px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      float: right;
      .calendar__popup-wrap-span {
        color: #ffffff;
      }
      &.__btn {
        width: 100%;
        float: none;
      }
    }
    .calendar__container-left {
      background: rgba(255, 255, 255, .1);
      float: left;
      font: 14px PT Sans,sans-serif;
      line-height: 39px;
      color: #fff;
      text-align: left;
      text-transform: uppercase;
      padding: 0 13px 0 12px;
      width: 68px;
    }
    .calendar__guest-popup {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
      .calendar__guest-popup-container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        box-shadow: 0 0 6px 3px rgb(0, 0, 0 / 24%);
        border-radius: 3px;
        position: relative;
        background: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 20px 20px 24px;
        font: 12px Arial,sans-serif;
        line-height: 15px;
        color: #3d3d3d;
        width: 300px;
        min-height: 268px;
        z-index: 100000;
        @media (max-width: 500px) {
          top: 50px;
        }
        .calendar__guests-header {
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(61,61,61,.24);
          .calendar__guests-title {
            font-size: 18px;
            font-weight: 600;
          }
        }
        .calendar__guests-groups {
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          font-size: 100%;
          vertical-align: baseline;
          background: transparent;
          .calendar__guests-group {
            padding: 10px 0 24px;
            border-bottom: 1px solid rgba(61,61,61,.24);
            .calendar__guests-group-header {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              margin-bottom: 8px;
              .calendar__guests-group-room {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                font-weight: 600;
                line-height: 32px;
                white-space: nowrap;
                span {
                  padding-left: 5px;
                }
              }
            }
          }
          .calendar__guests-group-select {
            display: flex;
            padding-bottom: 20px;
            padding-top: 15px;
            border-bottom: 1px solid rgba(61, 61, 61, 0.24);
            .calendar__guests-adults {
              padding-right: 8px;
              flex: 1 1 50%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 50%;
              max-width: 50%;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              .calendar__guests-adults-label {
                opacity: .8;
                padding-bottom: 5px;
              }
              .calendar__guests-adults-control {
                position: relative;
                .calendar__input-step.minus {
                  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%229e%22%20d%3D%22M19%2011H13H11H5V13H11H13H19V11Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
                  position: absolute;
                  top: 0;
                  left: 0;
                  margin: 8px;
                  width: 24px;
                  height: 24px;
                  border-radius: 3px;
                  background-color: rgba(102,102,102,.09);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  color: #666;
                  cursor: pointer;
                  -webkit-tap-highlight-color: transparent;
                  &.__no-drop {
                    cursor: no-drop;
                  }
                }
                .calendar__input {
                  padding: 0 40px;
                  height: 40px;
                  line-height: 40px;
                  font-size: 21px;
                  border-radius: 3px;
                  border: 1px solid rgba(61,61,61,.38);
                  background: #fff;
                  color: #3d3d3d;
                  text-align: center;
                  -webkit-box-sizing: border-box;
                  box-sizing: border-box;
                  width: 100%;
                }
                .calendar__input-step.plus {
                  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M19%2011H13V5H11V11H5V13H11V19H13V13H19V11Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
                  right: 0;
                  position: absolute;
                  top: 0;
                  margin: 8px;
                  width: 24px;
                  height: 24px;
                  border-radius: 3px;
                  background-color: rgba(102,102,102,.09);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  color: #666;
                  cursor: pointer;
                  -webkit-tap-highlight-color: transparent;
                }
              }
            }
            .calendar__guests-childs {}
          }
        }
        .calendar__guests-controls {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          .calendar__guests-btn-container {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            border-radius: 3px;
            margin-right: 16px;
            flex: 1 1 auto;
            min-width: 104px;
            background: #666;
            font-weight: 600;
            color: #ffffff;
          }
          .calendar__guests-btn-container.plus{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            border-radius: 3px;
            margin-right: 16px;
            flex: 1 1 auto;
            min-width: 104px;
            background: rgba(102,102,102,.09);
            color: #666;
            font-weight: 400;
            height: 40px;
            width: 100%;
          }
        }
      }
    }
    .calendar__container-middle {
      margin: 0;
      width: 158px;
      height: 39px;
      text-decoration: none;
      background: transparent;
      border: 0;
      padding: 0 0 0 19px;
      float: left;
      line-height: 39px;
    }
    .calendar__popup {
      position: absolute;
      top: 8px;
      left: 80px;
      width: 50%;
    }
    img {
      position: absolute;
      top: 8px;
      right: 10px;
    }
    .calendar {
      position: absolute;
      display: initial;
      top: -100px;
      left: initial;
      z-index: 10;
      --side-padding: 20px;
      --border-radius: 34px;
      --accent-br: 15px;
      width: 400px;
      @media (max-width: 768px) {
        width: 300px;
        top: -250px;
      }
      @media (max-width: 500px) {
        width: 300px;
        top: -10px;
      }
      select {
        background-color: #f3f4f6;
        padding: 15px 20px;
      }
      &__opts,
      &__buttons {
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
      }
      &__opts {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        padding: 20px var(--side-padding);
      }
      &__body {
        background-image: linear-gradient(to bottom, #f3f4f6, #fff);
      }
      &__days {
        background-color: #fff;
        padding: 0 var(--side-padding) 10px;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        & > div {
          text-align: center;
          font: {
            weight: 700;
            size: 1.02rem;
          }
          color: #c5c8ca;
        }
      }
      &__dates {
        padding: 10px var(--side-padding);
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
      &__date {
        --height: calc(400px / 6 - var(--side-padding));
        text-align: center;
        height: var(--height);
        line-height: var(--height);
        font: {
          weight: 600;
          size: 1.02rem;
        }
        cursor: pointer;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0);
          width: 100%;
          height: calc(var(--height) * 0.9);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: var(--accent-br);
          transition: background-color 0.3s ease;
        }
        &:not(.calendar__date--selected):not(.calendar__date--grey):hover::before {
          background-color: #ededed;
        }
        &--grey {
          color: #c5c8ca;
          cursor: not-allowed;
        }
        &--selected {
          color: #ff374b;
          &::before {
            background-color: #ffeaec;
            border-radius: 0px;
          }
        }
        &--first-date {
          &::before {
            border-top-left-radius: var(--accent-br);
            border-bottom-left-radius: var(--accent-br);
          }
        }
        &--last-date {
          &::before {
            border-top-right-radius: var(--accent-br);
            border-bottom-right-radius: var(--accent-br);
          }
        }
        &--range-start {
          &::after {
            content: "";
            position: absolute;
            bottom: 3px;
            border-radius: 24px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #ff374b;
            width: 10px;
            height: 4px;
          }
        }
        &--range-end {
          color: #fff;
          &::before {
            box-shadow: 0 15px 20px -3px rgba(255, 55, 75, 0.35);
            background-color: #ff374b;
            border-radius: var(--accent-br);
            z-index: 1;
          }
          &::after {
            content: "";
            position: absolute;
            height: calc(var(--height) * 0.9);
            background-color: #ffeaec;
            width: 50px;
            top: 50%;
            right: 50%;
            transform: translateY(-50%);
          }
        }
        span {
          position: relative;
          z-index: 1;
        }
      }
      &__buttons {
        padding: 10px var(--side-padding) 20px;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
      &__button {
        cursor: pointer;
        &--grey {
          background-color: #f3f4f6;
        }
        &--primary {
          background-color: #1752ff;
          color: #fff;
          transition: box-shadow 0.3s cubic-bezier(0.21, 0.68, 0.09, 0.27),
          transform 0.2s linear;

          &:hover {
            box-shadow: 0 20px 30px -13px rgba(23, 82, 255, 0.45);
            transform: translateY(-3px);
          }

          &:active {
            box-shadow: 0 10px 10px -6px rgba(23, 82, 255, 0.45);
            transform: translateY(-1px);
          }
        }
      }
      select,
      button {
        font: {
          family: inherit;
          weight: 700;
          size: 1.02rem;
        }
        border-radius: 20px;
        outline: none;
        border: 0;
        padding: 15px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      select {
        background: {
          image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E");
          size: 24px;
          repeat: no-repeat;
          position: calc(100% - var(--side-padding)) center;
        }
      }
    }
  }
}
</style>